import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';
import data from '../../../config/site_data.json';
import {Container, Section} from '../../Grid';
import {Heading} from '../../Typography';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {Button} from '../../Button';

const Wrapper = styled(Section)`
  ${tw`relative`}

  @media ${props => props.theme.screens.xl} {
    margin: 180px auto 0px;
    max-width: 1352px;
  }
`;

const Content = styled.div`
  @media ${props => props.theme.screens.sm} {
    max-width: 80%;
    margin: 0 auto;
  }
  @media ${props => props.theme.screens.md} {
    max-width: 50%;
    margin: 0;
  }

  @media ${props => props.theme.screens.xl} {
    max-width: 564px;
  }
`;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}

  @media ${props => props.theme.screens.md} {
    font-size: 40px;
    line-height: 127%;
  }
`;

const Text = styled.p`
  ${tw`my-6  text-secondary`}
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  a {
    color: #039259;
    text-decoration: underline;
  }

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 46px;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;

  @media ${props => props.theme.screens.md} {
    margin-left: auto;
    position: absolute;
    right: 0;
    width: 50%;
    top: 50%;
    transform: translateY(-50%);
    /* max-width: 637px; */
  }
  @media ${props => props.theme.screens.md} {
    width: 60%;
    transform: translate(15%, -50%);
  }
`;

const PageDescription: React.FC<{button: boolean}> = ({button}) => {
  const {t} = useTranslation();

  const {heading, text, text_2, text_3, button_text} =
    data.upholstery.description;

  const {image} = useStaticQuery(graphql`
    query {
      image: file(relativePath: {eq: "blue-vacuum.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            quality: 90
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Container>
        <Content>
          <StyledHeading>{t(heading)}</StyledHeading>
          <Text dangerouslySetInnerHTML={{__html: t(text)}} />
          <Text dangerouslySetInnerHTML={{__html: t(text_2)}} />
          <Text dangerouslySetInnerHTML={{__html: t(text_3)}} />
          {button && <StyledButton>{t(button_text)}</StyledButton>}
        </Content>
        <ImageContainer>
          <GatsbyImage image={getImage(image.childImageSharp)} />
        </ImageContainer>
      </Container>
    </Wrapper>
  );
};

export default PageDescription;
