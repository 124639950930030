import React, {useEffect, useState} from 'react';
import data from '../../../config/site_data.json';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import tw, {styled, theme} from 'twin.macro';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Container, Section} from '../../Grid';
import {Heading} from '../../Typography';
import SVGIcon from '../../SVGIcon';
import useWindowSize from '../../../hooks/useWindowSize';

const StyledHeading = styled(Heading)`
  ${tw`text-secondary mb-8 lg:mb-24`}
`;

const ItemWrapper = styled.div`
  ${tw`md:grid md:grid-cols-3 gap-x-4`}
`;

const Item = styled.div`
  ${tw`flex items-center p-2 mb-4`}
  background-color: #f6f7f6;
  border-radius: 17px;
`;

const Info = styled.div`
  ${tw`ml-4`}
`;

const Title = styled.h4`
  ${tw`text-secondary`}

  @media ${props => props.theme.screens.md} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Description = styled.span`
  ${tw`text-200`}

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 140%;
  }
`;

const Seemore = styled.span`
  ${tw`flex justify-end items-center text-200 mt-8 md:hidden`}

  svg {
    margin-left: 14px;
  }

  font-size: 17px;
  line-height: 123%;
  letter-spacing: -0.01em;
`;

const ImageWrapper = styled.div`
  background-color: #fff;
  width: 160px;
  height: 93px;
  border-radius: 10px;
  padding: 6px;
`;

const Space = styled.div`
  width: 165px;
`;

const ITEMS_INCREMENT = 4;

const PriceList: React.FC<{pricelist: any}> = ({pricelist}) => {
  const {t} = useTranslation();
  const {heading} = data.upholstery.price_list;

  const size = useWindowSize();

  const totalItems = pricelist.length;

  const [limit, setLimit] = useState(ITEMS_INCREMENT);
  const [seeLess, setSeeless] = useState(false);

  useEffect(() => {
    if (limit >= totalItems) {
      setSeeless(true);
    } else {
      setSeeless(false);
    }
  }, [limit]);

  useEffect(() => {
    const breakpoint = theme('screens.md').replace('px', '');

    if (size.width >= breakpoint) {
      setLimit(totalItems);
    }
  }, [size]);

  const handleSeeMore = () => {
    if (seeLess) {
      setLimit(ITEMS_INCREMENT);
      return;
    }
    setLimit(limit + ITEMS_INCREMENT);
  };

  return (
    <Section>
      <Container>
        <StyledHeading>{t(heading)}</StyledHeading>
        <ItemWrapper>
          {pricelist.map(({node}: any, index: number) => {
            if (index + 1 > limit) return null;
            return (
              <Item key={node.id}>
                <Space>
                  <ImageWrapper>
                    <GatsbyImage
                      image={getImage(node.thumbnail)}
                      style={{height: 85}}
                      imgStyle={{objectFit: 'contain'}}
                    />
                  </ImageWrapper>
                </Space>

                <Info>
                  <Title>{t(node.title)}</Title>
                  <Description>
                    {t(node.amount)} {t(node.unit)}
                  </Description>
                </Info>
              </Item>
            );
          })}
        </ItemWrapper>

        <Seemore onClick={handleSeeMore}>
          {seeLess ? (
            <>
              {t('see_less')}
              <SVGIcon icon="regular-chevron-up" />{' '}
            </>
          ) : (
            <>
              {t('see_more')}
              <SVGIcon icon="regular-chevron-down" />{' '}
            </>
          )}
        </Seemore>
      </Container>
    </Section>
  );
};

export default PriceList;
