import React from 'react';
import {graphql} from 'gatsby';
import config from '../config/site_data.json';

import Layout from '../components/Layout';
import PageDescription from '../components/Page/upholstery/PageDescription';
import Hero from '../components/Page/upholstery/PageHero';
import CleaningList from '../components/Page/upholstery/CleaningList';
import UpholsteryHotspot from '../components/Page/upholstery/UpholsteryHotspot';
import PriceList from '../components/Page/upholstery/PriceList';
import BookingBanner from '../components/Page/BookingBanner';
import FAQ from '../components/Page/FAQ';
import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import OurWork from '../components/Page/home/ourwork';
import PageHero from '../components/shared/hero';

const HomeCleaningPage = ({data}: any) => {
  const {language, t} = useI18next();
  const {background, backgroundMobile, backgroundTablet} = data;
  const {heading, description} = config.upholstery.our_work;
  const {heading: heroHeading, text, button_text} = config.upholstery.hero;

  return (
    <Layout headerStyle="upholstery">
      <SEO
        title={t('upholstery_cleaning_seo_title')}
        description={t('upholstery_cleaning_seo_description')}
        keywords={t('upholstery_cleaning_seo_keywords', {returnObjects: true})}
        lang={language}
      />
      <PageHero
        content={{
          heading: t(heroHeading),
          text: t(text),
          button: t(button_text),
        }}
        imgPosition={'95% 0%'}
        imgMobilePosition={'0% 100%'}
        imgObj={{
          background,
          backgroundMobile,
          backgroundTablet,
        }}
      />
      <PageDescription button={false} />
      <UpholsteryHotspot />
      <CleaningList />
      <PriceList pricelist={data.pricelist.edges} />
      <OurWork
        ourwork={data.ourwork.edges}
        title={heading}
        description={description}
      />
      <BookingBanner
        filename="upholstery-bookingbanner.jpg"
        heading={`save_time_for_happy_things`}
        color="primary"
      />
      <FAQ faqs={data.faq.edges} />
    </Layout>
  );
};

export default HomeCleaningPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "upholstery", "seo_upholstery"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    background: file(relativePath: {eq: "carpet-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "carpet-hero-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "carpet-hero-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "upholstery"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
    ourwork: allContentfulOurWork(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}, category: {eq: "upholstery"}}
    ) {
      edges {
        node {
          id
          category
          images {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    pricelist: allContentfulItems(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          amount
          id
          unit
          title
          thumbnail {
            gatsbyImageData(
              width: 160
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
