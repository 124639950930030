import React from 'react';
import data from '../../../config/site_data.json';
import {Container, Section} from '../../Grid';
import RegularList from '../home-cleaning/RegularList';

const CleaningList = () => {
  const {heading, items} = data.upholstery.cleaning_list;

  return (
    <Section>
      <Container>
        <RegularList title={heading} items={items} />
      </Container>
    </Section>
  );
};

export default CleaningList;
