import React from 'react';
import data from '../../../config/site_data.json';
import tw, {styled} from 'twin.macro';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Container, Section} from '../../Grid';
import {Heading} from '../../Typography';
import HotspotImage from '../../HotspotImage';

const StyledHeading = styled(Heading)`
  ${tw`text-secondary mb-8 lg:mb-24`}
`;

const ImageWrapper = styled.div`
  border-radius: 24px;
  overflow: hidden;
`;

const UplhosteryHotspots = () => {
  const {t} = useTranslation();
  const {heading, filename, hotspots} = data.upholstery.hotspot;

  return (
    <Section>
      <Container>
        <StyledHeading>{t(heading)}</StyledHeading>
        <ImageWrapper>
          <HotspotImage filename={filename} hotspots={hotspots} />
        </ImageWrapper>
      </Container>
    </Section>
  );
};

export default UplhosteryHotspots;
